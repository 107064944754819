import React from "react";
// import './Orient.css';

const Orient = ({ orient }) => {
	const height1 =
		orient === "L" || orient === "R" ? "50%" : orient === "B" ? "40%" : "10%";
	const height2 =
		orient === "L" || orient === "R" ? "50%" : orient === "B" ? "10%" : "40%";
	const width1 =
		orient === "T" || orient === "B" ? "50%" : orient === "R" ? "40%" : "10%";
	const width2 =
		orient === "T" || orient === "B" ? "50%" : orient === "R" ? "10%" : "40%";
	const flexDirection = orient === "T" || orient === "B" ? "column" : "row";
	return (
		<div
			className="Orient-container"
			style={{
				display: "flex",
				flexDirection,
				height: "100%",
				width: "100%",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<div
				style={{ height: height1, width: width1, border: "1px solid black" }}
			/>
			<div
				style={{ height: height2, width: width2, border: "1px solid black" }}
			/>
		</div>
	);
};
export default Orient;
