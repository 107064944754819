import React from "react";
import "./Nav.css";

const Nav = () => {
	return (
		<div className="Nav-container">
			<div>Nav</div>
			<button style={{ width: "100%", height: 50 }}>Home</button>
			<button style={{ width: "100%", height: 50 }}>SignUp</button>
		</div>
	);
};
export default Nav;
