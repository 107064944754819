import React, { useState } from "react";
// import './IdeNav.css';
import Orient from "./Orient";

const btnStyle = (orient, o) => ({
	height: orient === "L" || orient === "R" ? "100%" : "calc(100% / 5)",
	width: orient === "L" || orient === "R" ? "calc(50% / 4)" : "100%",
	fontSize: "1rem",
	cursor: "pointer",
	boxSizing: "border-box",
	margin: 0,
	border: "none",
	backgroundColor: orient === o ? "skyblue" : "white"
});

const IdeNav = ({ convertToHtml, ornt }) => {
	const { orient, setOrient } = ornt;
	const changeOrient = o => {
		setOrient(o);
		localStorage["orient"] = o;
	};

	const flexDirection =
		orient === "L"
			? "row"
			: orient === "R"
			? "row-reverse"
			: orient === "T"
			? "column"
			: "column-reverse";
	const navHeight = orient === "L" || orient === "R" ? "5vh" : "30vh";

	const keyEventRC = e => {
		if (e.ctrlKey === true && e.key === "Enter") {
			convertToHtml();
		}
	};
	window.addEventListener("keypress", keyEventRC);
	return (
		<div
			className="IdeNav-container"
			style={{ height: navHeight, display: "flex", flexDirection }}
		>
			<button onClick={() => changeOrient("L")} style={btnStyle(orient, "L")}>
				<Orient orient="L" />
			</button>
			<button onClick={() => changeOrient("T")} style={btnStyle(orient, "T")}>
				<Orient orient="T" />
			</button>
			<button onClick={() => changeOrient("R")} style={btnStyle(orient, "R")}>
				<Orient orient="R" />
			</button>
			<button onClick={() => changeOrient("B")} style={btnStyle(orient, "B")}>
				<Orient orient="B" />
			</button>
			<button
				id="run-code-btn"
				style={{
					height: orient === "L" || orient === "R" ? "100%" : "calc(100% / 5)",
					width: orient === "L" || orient === "R" ? "50%" : "100%",
					background: "green",
					color: "white",
					fontSize: "1rem",
					cursor: "pointer",
					boxSizing: "border-box",
					margin: 0,
					border: "none",
					transition: "500ms"
				}}
				onClick={() => convertToHtml()}
			>
				<strong title="ctrl + Enter">Run Code</strong>
				{/* <br />
				<sub>(ctrl + enter)</sub> */}
			</button>
		</div>
	);
};

export default IdeNav;
