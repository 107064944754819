import React, { useState } from "react";
// import "./HTML.css";

const initTaStyle = {
	background: "white",
	height: "90%",
	width: "100%",
	fontSize: "16px",
	padding: 10,
	boxSizing: "border-box",
	resize: "none", // vertical, horizontal
	transition: "500ms"
};

let initLabelStyle = {
	height: "10%",
	width: "100%",
	background: "skyblue",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "row"
};

export const Html = ({ onChangeHtml, ornt, val }) => {
	const [taStyle, setTaStyle] = useState(initTaStyle);
	const [labelStyle, setLabelStyle] = useState(initLabelStyle);

	const collapseToggle = () =>
		taStyle.height === "90%"
			? setTaStyle({ ...taStyle, height: 0 })
			: setTaStyle({ ...taStyle, height: "90%" });

	const height = ornt === "L" || ornt === "R" ? "calc(95vh / 3)" : "30vh";
	const width = ornt === "L" || ornt === "R" ? "100%" : "calc(95vw / 3)";

	return (
		<div
			id="html-ide"
			// onClick={collapseToggle}
			style={{ height, width }}
		>
			<div style={labelStyle}>HTML</div>
			<textarea
				placeholder={`<main class="body">\n\tYour code starts in this element\n</main>`}
				onChange={onChangeHtml}
				style={taStyle}
				value={val}
			/>
		</div>
	);
};
