import React from "react";

export const Css = ({ onChangeCss, ornt, val }) => {
	let taStyle = {
		background: "white",
		height: "90%",
		width: "100%",
		fontSize: "16px",
		padding: 10,
		boxSizing: "border-box",
		resize: "none", // vertical, horizontal
		transition: "500ms"
	};
	let labelStyle = {
		height: "10%",
		width: "100%",
		background: "skyblue",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row"
	};

	const height = ornt === "L" || ornt === "R" ? "calc(95vh / 3)" : "30vh";
	const width = ornt === "L" || ornt === "R" ? "100%" : "calc(95vw / 3)";

	return (
		<div id="css-ide" style={{ height, width }}>
			<div style={labelStyle}>CSS</div>
			<textarea
				placeholder={`.body {\n\tbackground-color: white;\n}`}
				onChange={onChangeCss}
				style={taStyle}
				value={val}
			/>
		</div>
	);
};
