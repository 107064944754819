import React from "react";
// import './Page.css';

const Page = ({ heightP, widthP, mainW }) => {
	return (
		<div className="Page-container">
			<div style={{ height: heightP, width: widthP }}>
				<style id="style" />
				<main
					id="new-html"
					className="body"
					style={{
						height: "100%",
						width: mainW
					}}
				/>
			</div>
		</div>
	);
};
export default Page;
