import React, { useState } from "react";
import "./App.css";
import Nav from "./components/nav/Nav";
import IdeNav from "./components/ideNav/IdeNav";
import Page from "./components/page/Page";
import { Html } from "./components/html/HTML";
import { Css } from "./components/css/CSS";
import { Js } from "./components/js/JS";

function App() {
	const [html, setHtml] = useState(localStorage["html"] || "");
	const [css, setCss] = useState(localStorage["css"] || "");
	const [js, setJs] = useState(localStorage["js"] || "");
	const [orient, setOrient] = useState(localStorage["orient"] || "L");

	const onChangeHtml = e => setHtml(e.target.value);
	const onChangeCss = e => setCss(e.target.value);
	const onChangeJs = e => setJs(e.target.value);

	const convertToHtml = () => {
		let newHtml = document.getElementById("new-html");
		let style = document.getElementById("style");
		// let script = document.getElementById("script");
		newHtml.innerHTML = html;
		localStorage["html"] = html;
		if (css) {
			style.type = "text/css";
			style.innerHTML = css;
			localStorage["css"] = css;
		}
		if (js) {
			const head = document.getElementsByTagName("head")[0];
			const script = document.createElement("script");
			script.innerHTML = js;
			localStorage["js"] = js;
			script.type = "text/javascript";
			// script.onload = function () {
			// 	callFunctionFromScript();
			// }
			// script.src = 'path/to/your-script.js';
			head.appendChild(script);
		}
	};

	// const ide = document.querySelector("#IDE");
	// Object.freeze(ide);

	const flexDirection = orient === "L" || orient === "R" ? "row" : "column";
	const width = orient === "L" || orient === "R" ? "30vw" : "95vw";
	const height = orient === "L" || orient === "R" ? "100vh" : "30vh";

	const widthP = orient === "L" || orient === "R" ? "65vw" : "95vw";
	const heightP = orient === "L" || orient === "R" ? "100vh" : "70vh";

	const mainW = orient === "L" || orient === "R" ? "70vw" : "100vw";

	let ide = (
		<div
			id="IDE"
			style={{
				height,
				width,
				display: "flex",
				flexDirection: orient === "T" || orient === "B" ? "row" : "column",
				transition: "500ms"
			}}
		>
			<IdeNav
				ornt={{ orient, setOrient }}
				convertToHtml={() => convertToHtml()}
			/>
			<Html ornt={orient} val={html} onChangeHtml={onChangeHtml} />
			<Css ornt={orient} val={css} onChangeCss={onChangeCss} />
			<Js ornt={orient} val={js} onChangeJs={onChangeJs} />
		</div>
	);

	let page = <Page heightP={heightP} widthP={widthP} mainW={mainW} />;

	let content = [];
	if (orient === "T" || orient === "L") {
		content = [ide, page];
	} else {
		content = [page, ide];
	}

	return (
		<div className="App">
			<Nav />
			<div
				style={{
					height: "100vh",
					width: "95vw",
					display: "flex",
					flexDirection
				}}
			>
				{content}
			</div>
		</div>
	);
}

export default App;

{
	/* <div id="IDE" style={{ height, width, display }}>
	<IdeNav
		ornt={{ orient, setOrient }}
		convertToHtml={() => convertToHtml()}
	/>
	{/* <div style={{ height: "7.5%" }}>
					Something
					<button
						style={{
							height: "100%",
							width: "50%",
							background: "red",
							color: "white",
							fontSize: "2rem",
							cursor: "pointer"
						}}
						onClick={() => convertToHtml()}
					>
						Run Code
					</button> */
}
{
	/* </div> */
}
// <Html ornt={orient} onChangeHtml={onChangeHtml} />
// <Css ornt={orient} onChangeCss={onChangeCss} />
// <Js ornt={orient} onChangeJs={onChangeJs} />

{
	/* <button
					style={{
						height: "7.5%",
						width: "100%",
						background: "red",
						color: "white",
						fontSize: "2rem",
						cursor: "pointer"
					}}
					onClick={() => convertToHtml()}
				>
					Run Code
				</button> */
}
// </div>
// 	<div style={{ background: "yellow", width: !width }}>
// 		<style id="style" />
// 		<main
// 			id="new-html"
// 			className="body"
// 			style={{
// 				height: "100%",
// 				width: "70vw"
// 			}}
// 		/>
// 		{/* <script id="script" /> */}
// 	</div> */}
